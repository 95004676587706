import React, { useState, useEffect } from "react";
import "./Contact.css";

export const Contact: React.FC = () => {
  const [formStatus, setFormStatus] = useState<string | null>(null);

  // Dynamically load SMTP.js
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script); // Clean up the script when component unmounts
    };
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const subject = formData.get("subject");
    const message = formData.get("message");

    // Use SMTP.js to send email
    if ((window as any).Email) {
      (window as any).Email.send({
        SecureToken: "e636bc25-30e6-412c-a55f-cee36877f5d4",
        To: "aspencask@gmail.com",
        From: "aspencask@gmail.com",
        Subject: subject as string,
        Body: `Name: ${name} <br/> Email: ${email} <br/> Phone: ${phone} <br/> Message: ${message}`,
      }).then(
        (response: string) => {
          if (response === "OK") {
            setFormStatus("Message sent successfully!");
            (e.target as HTMLFormElement).reset(); // Reset form on successful submission
          } else {
            setFormStatus("Failed to send message. Please try again later.");
          }
        },
        (error: any) => {
          setFormStatus("Error sending message: " + error);
        }
      );
    } else {
      setFormStatus("Failed to load email sending service. Try again later.");
    }
  };

  return (
    <div className="contact">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Contact Us</h1>
          <p>Get in touch with us for all your software solutions!</p>
          
        </div>
      </section>

      <p className="cpara">
        At AspenCask Soln, we are committed to providing exceptional solutions
        for your business needs. Whether you have questions about our services
        or need a custom software solution, we're here to help!
      </p>
      <p className="contactheading">
        Please fill out the form below, and one of our representatives will get
        back to you promptly.
      </p>

      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="phone">Phone</label>
        <input type="tel" id="phone" name="phone" required />

        <label htmlFor="subject">Subject</label>
        <input type="text" id="subject" name="subject" required />

        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" required></textarea>

        <button type="submit">Send Message</button>
      </form>

      {/* Display success or error message as a popup */}
      {formStatus && (
        <div className={`popup ${formStatus.includes("successfully") ? "success" : "error"}`}>
          <span>{formStatus}</span>
          <button className="close-btn" onClick={() => setFormStatus(null)}>X</button>
        </div>
      )}

      <div className="contact-details">
        <h2>Our Office</h2>
        <p>Raj Bagh, Ghaziabad</p>
        <p>Uttar Pradesh, 201005</p>
        <p>
          Email:{" "}
          <a href="mailto:aspencask@gmail.com">aspencask@gmail.com</a>
        </p>
        <p>
          Phone: <a href="tel:+919608674820">+91 9608674820</a>
        </p>
      </div>

      
      <div className="additional-info-wrapper">
  <div className="additional-info">
    <h2>Why Choose Us?</h2>
    <ul>
      <li>Expertise in web and app development tailored to your needs.</li>
      <li>Comprehensive software solutions designed to improve your business efficiency.</li>
      <li>Dedicated support team to assist you at every stage of the project.</li>
      <li>Agile methodologies to ensure timely delivery and flexibility.</li>
    </ul>
  </div>
</div>


      <div className="social-media">
        <h2>Follow Us</h2>
        <p>Stay updated with our latest projects and offers:</p>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61566376989450&mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
         
          <li>
            <a
              href="https://www.linkedin.com/company/aspencask-solution/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/aspencask?igsh=MnhlN2IwNmxpaHli"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
