import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SideHeader from "./components/SideHeader/SideHeader";
import Footer from "./components/Footer/Footer";
import {
  AboutUs,
  Blog,
  CareersPage,
  Contact,
  Home,
  PortfolioPage,
  Services,
} from "./pages";

import { HoverProvider } from "./context/HoverContext"; // Import the HoverProvider for global hover state
import initializeAnalytics from "./components/analytics"; // Import the analytics initialization
import usePageTracking from "./components/usePageTracking"; // Import the custom page tracking hook
import "./App.css";

const App: React.FC = () => {
  const location = useLocation();

  // Initialize Google Analytics when the app mounts
  useEffect(() => {
    initializeAnalytics();
  }, []);

  // Track page views whenever the route changes
  usePageTracking();

  // Set document title based on the route
  useEffect(() => {
    const pageTitles: { [key: string]: string } = {
      "/": "AspenCask - Home",
      "/about": "AspenCask - About",
      "/services": "AspenCask - Services",
      "/portfolio": "AspenCask - Portfolio",
      "/blog": "AspenCask - Blog",
      "/contact": "AspenCask - Contact",
      "/careers": "AspenCask - Careers",
    };

    document.title = pageTitles[location.pathname] || "AspenCask";
  }, [location]);

  return (
    <HoverProvider>
      <div className="app">
        <SideHeader />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </HoverProvider>
  );
};

export default App;
