// src/components/analytics.ts

import ReactGA from 'react-ga4';

// Initialize Google Analytics with your tracking ID
const initializeAnalytics = () => {
  ReactGA.initialize('G-MZMJ6M7FLX'); // Replace with your GA tracking ID
};

export default initializeAnalytics;
