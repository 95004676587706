import React, { useState } from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  const [showComingSoon, setShowComingSoon] = useState(false);

  const handleComingSoon = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowComingSoon(true);
    setTimeout(() => setShowComingSoon(false), 2000);
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src="assests/logo.png" alt="Company Logo" />
          <address>
            <p>Raj bagh, Ghaziyabad, uttarpradesh</p>
            
          </address>
        </div>

        <div className="footer-columns">
          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>Newsroom</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>Legal Info</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Tools</h4>
            <ul>
              <li>
                <a href="#" onClick={handleComingSoon}>Analytics</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>SEO Tools</a>
              </li>
              <li>
                <a href="/portfolio">Projects</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>Affiliate Program</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Help</h4>
            <ul>
              <li>
                <a href="#" onClick={handleComingSoon}>Knowledge Base</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>API</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>Support</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>FAQ</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Follow Us</h4>
            <ul>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61566376989450&mibextid=ZbWKwL">Facebook</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/aspencask-solution/">LinkedIn</a>
              </li>
              <li>
                <a href="https://www.instagram.com/aspencask?igsh=MnhlN2IwNmxpaHli">Instagram</a>
              </li>
              <li>
                <a href="#" onClick={handleComingSoon}>Twitter</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 AspenCask Solution, Inc. All rights reserved.</p>
      </div>
      {showComingSoon && (
        <div className="coming-soon-message">
          Coming Soon! Stay tuned.
        </div>
      )}
    </footer>
  );
};

export default Footer;