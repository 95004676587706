import React, { useState, useRef, useEffect } from "react";
import formimgSpring from "../../assets/images/spring.jpg";
import formimgReact from "../../assets/images/react.svg"; // Add relevant images for new positions
import formimgDevOps from "../../assets/images/DevOps.webp";

import "./careers.css";

interface Job {
  title: string;
  description: string;
  salary: string;
  experience: string;
  education: string;
  skills: string[];
  image: string;
}

export const CareersPage: React.FC = () => {
  const [jobs] = useState<Job[]>([
    {
      title: "Spring Boot Developer",
      description: "Develop and maintain enterprise-level applications using Spring Boot.",
      salary: "Not Disclosed",
      experience: "2-4 years",
      education: "Bachelor’s in Computer Science or relevant field",
      skills: ["Spring Boot", "Java", "Microservices", "REST APIs", "SQL"],
      image: formimgSpring,
    },
    {
      title: "React Developer",
      description: "Build dynamic and responsive web applications using React.",
      salary: "Not Disclosed",
      experience: "1-3 years",
      education: "Bachelor’s in Computer Science or relevant field",
      skills: ["React", "JavaScript", "TypeScript", "Redux", "CSS"],
      image: formimgReact,
    },
    {
      title: "DevOps engineer",
      description: "Implement and maintain CI/CD pipelines, manage cloud infrastructure.",
      salary: "Not Disclosed",
      experience: "3-5 years",
      education: "Bachelor’s in Computer Science or relevant field",
      skills: ["CI/CD", "Docker", "Kubernetes", "AWS", "Jenkins"],
      image: formimgDevOps,
    },
  ]);

  const [showForm, setShowForm] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [qualification, setQualification] = useState<string>("");
  const [otherQualification, setOtherQualification] = useState<string>("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const jobsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script); // Clean up the script when component unmounts
    };
  }, []);

  const handleApplyClick = (job: Job) => {
    setSelectedJob(job);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedJob(null);
    setQualification("");
    setOtherQualification("");
    setShowSuccessMessage(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // Collecting form data
    const formData = new FormData(event.target as HTMLFormElement);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const phone = formData.get("phone") as string;
    const location = formData.get("location") as string;
    const qualificationValue = qualification === "Other" ? otherQualification : qualification;

    // Sending the email using SMTP.js
    window.Email.send({
      SecureToken: "bca87afd-73a0-4700-9b5c-e61e65aa3554", // Replace with your secure token
      To: "jobaspencask@gmail.com", // Change to your recipient email
      From: "jobaspencask@gmail.com", // Change to your sender email
      Subject: `Job Application: ${selectedJob?.title}`,
      Body: `
        <h2>Job Application for ${selectedJob?.title}</h2>
        <p><strong>Name:</strong> ${name}</p>
        <p><strong>Email:</strong> ${email}</p>
        <p><strong>Phone:</strong> ${phone}</p>
        <p><strong>Location:</strong> ${location}</p>
        <p><strong>Qualification:</strong> ${qualificationValue}</p>
      `,
    })
      .then((message: string) => {
        if (message === "OK") {
          setShowSuccessMessage(true);
        } else {
          alert("Failed to send email: " + message);
        }
      })
      .catch((error: any) => {
        console.error("Email sending error:", error);
        alert("An error occurred while sending the email.");
      });

    setShowForm(false);
  };

  const handleExploreClick = () => {
    if (jobsRef.current) {
      jobsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="careers-page-container">
      {/* Hero Section */}
      <div className="careers-hero-section-container">
        <h1 className="careers-hero-title">Join The Technology Revolution</h1>
        <p className="careers-hero-description">
          Be a part of AspenCask and build the future with us, working on cutting-edge technologies.
        </p>
        <button className="careers-hero-explore-button" onClick={handleExploreClick}>
          Explore Opportunities
        </button>
      </div>

      {/* Career Info Section */}
      <div className="careers-info-section-container">
        <h2 className="careers-info-title">Spring Boot Developer Opportunity</h2>
        <p className="careers-info-description">
          Join us as a Spring Boot Developer to create innovative software solutions at AspenCask.
        </p>
      </div>

      {/* Job Listings Section */}
      <div id="jobs-section" ref={jobsRef} className="careers-job-listings-container">
        {jobs.map((job, index) => (
          <div key={index} className="careers-job-card">
            <div className="careers-job-header">
              <img src={job.image} alt={job.title} className="careers-job-image" />
              <h2 className="careers-job-title">{job.title}</h2>
            </div>
            <p className="careers-job-description"><strong>Description:</strong> {job.description}</p>
            <p className="careers-job-salary"><strong>Salary:</strong> {job.salary}</p>
            <p className="careers-job-experience"><strong>Experience:</strong> {job.experience}</p>
            <p className="careers-job-education"><strong>Education:</strong> {job.education}</p>
            <p className="careers-job-skills"><strong>Skills Required:</strong> {job.skills.join(", ")}</p>
            <button className="careers-job-apply-button" onClick={() => handleApplyClick(job)}>
              Apply Now
            </button>
          </div>
        ))}
      </div>

      {/* Apply Form Modal */}
      {showForm && (
        <div className="careers-modal">
          <div className="careers-modal-content">
            <span className="careers-modal-close" onClick={handleCloseForm}>&times;</span>
            <h2 className="careers-modal-title">Apply for {selectedJob?.title}</h2>
            <form className="careers-apply-form" onSubmit={handleSubmit}>
              <label htmlFor="name" className="careers-form-label">
                Full Name:
                <input type="text" name="name" id="name" placeholder="Enter your full name" required />
              </label>
              <label htmlFor="email" className="careers-form-label">
                Email:
                <input type="email" name="email" id="email" placeholder="Enter your email address" required />
              </label>
              <label htmlFor="phone" className="careers-form-label">
                Phone Number:
                <input type="tel" name="phone" id="phone" placeholder="Enter your phone number" required />
              </label>
              <label htmlFor="qualification" className="careers-form-label">
                Highest Qualification:
                <select name="qualification" id="qualification" value={qualification} onChange={(e) => setQualification(e.target.value)} required>
                  <option value="" disabled>Select your highest qualification</option>
                  <option value="Bachelor">Bachelor</option>
                  <option value="Master">Master</option>
                  <option value="Diploma">Diploma</option>
                  <option value="Other">Other</option>
                </select>
              </label>
              {qualification === "Other" && (
                <label htmlFor="otherQualification" className="careers-form-label">
                  Please specify:
                  <input type="text" name="otherQualification" id="otherQualification" placeholder="Enter your qualification" value={otherQualification} onChange={(e) => setOtherQualification(e.target.value)} />
                </label>
              )}
              <label htmlFor="location" className="careers-form-label">
                Location:
                <input type="text" name="location" id="location" placeholder="Enter your location" required />
              </label>
              <label htmlFor="resume" className="careers-form-label">
                Resume (PDF only):
                <input type="file" name="resume" id="resume" accept=".pdf" required />
              </label>
              <button type="submit" className="careers-form-submit-button">Submit Application</button>
            </form>
          </div>
        </div>
      )}

      {/* Success Message */}
      {showSuccessMessage && (
        <div className="careers-success-message">
          <h2>Your application has been submitted successfully!</h2>
          <button className="careers-success-close-button" onClick={handleCloseForm}>Close</button>
        </div>
      )}
    </div>
  );
};
